import {link} from 'fs';

const settings = {
	NkIcon: {
		icons: {
			'down-arrow-alt': ' bx-down-arrow-alt',
			'left-arrow-alt': ' bx-left-arrow-alt',
			'right-arrow-alt': ' bx-right-arrow-alt',
		},
	},
	NkCarousel: {
		fixedClasses: {
			wrapper: 'nk-carousel-wrapper',
		},
		variants: {
			default: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoplay: {
						delay: 2500,
					},
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1600: {
							slidesPerView: 4,
							spaceBetween: 24,
							centeredSlides: false,
						},
					},
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: 'flex items-center justify-center',
				slideContent: 'h-full w-full',
				slideBackgroundImage: '',
				controls: 'flex justify-between items-center',
				buttonWrapper: '',
				button:
					'transition ease-out duration-300 justify-center items-center text-center text-secondary-element text-xl hover:text-secondary-element--hover focus:text-secondary-element--active hidden sm:inline-flex',
				buttonLeft: 'left-0',
				buttonRight: 'right-0',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-5 mx-auto sm:mx-0',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-secondary-element hover:bg-secondary-element--hover is-active:bg-secondary-element transition',
				paginationButtonActive: 'is-active',
			},
			gallery: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.5,
					centeredSlides: true,
					pagination: false,
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: '',
				slideContent:
					'bg-neurtral-surface h-full flex justify-center items-center relative z-0 overflow-hidden',
				slideBackgroundImage: '',
				buttonWrapper:
					'absolute top-1/2 left-0 w-full flex z-10 justify-between transform -translate-y-1/2 pointer-events-none px-6',
				button:
					'pointer-events-auto rounded transition ease-out duration-300 justify-center items-center text-center text-secondary-on-element text-xl inline-flex bg-secondary-element hover:bg-secondary-element--hover focus:bg-secondary-element--active',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-5',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-primary-element hover:bg-primary-element--hover is-active:bg-secondary-element transition',
				paginationButtonActive: 'is-active',
			},
			news: {
				sliderOptions: {
					spaceBetween: 16,
					centeredSlides: false,
					slidesPerView: 1.25,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
							navigation: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
							navigation: false,
						},
						1024: {
							slidesPerView: 2.5,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
					},
				},
				wrapper: 'relative min-h-full h-full',
				slider: 'sm:overflow-hidden min-h-full h-full',
				sliderItem:
					'border-neutral-border border shadow-md rounded-lg min-h-full h-auto swiper-slide--news',
				slideContent: 'h-full relative overflow-hidden min-h-full',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 -mt-6 hidden p-2',
				button:
					'rounded-full transition ease-out duration-300 justify-center items-center text-center text-primary-text text-xl inline-flex border border-primary-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-secondary-element hover:bg-secondary-element--hover is-active:bg-secondary-element transition',
				paginationButtonActive: 'is-active',
			},
			calendar: {
				sliderOptions: {
					spaceBetween: 16,
					centeredSlides: false,
					slidesPerView: 1.25,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
							navigation: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
							navigation: false,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
					},
				},
				wrapper: 'relative min-h-full h-full',
				slider: ' min-h-full h-full',
				sliderItem: '',
				slideContent:
					'h-full flex flex-col justify-between relative z-0 min-h-full space-y-12 text-neutral-text-high-contrast group',
				slideBackgroundImage: '',
				controls: 'flex justify-center md:justify-between items-center mt-6',
				buttonWrapper: 'w-full md:flex justify-end space-x-2 hidden',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-secondary-on-element bg-secondary-element hover:bg-secondary-element--hover focus:bg-secondary-element--active text-sm w-12 h-12',
				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper: 'block',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-secondary-element opacity-50 hover:opacity-75 is-active:opacity-100 transition focus-visible:scale-[90%] focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			hero: {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'slide',
					autoplay: {
						delay: 6000,
					},
					slideEffect: {
						shadow: true,
						translate: ['120%', 0, -500],
					},
				},
				wrapper: 'relative w-full h-full bg-secondary-surface',
				slider: 'h-full min-h-full',
				sliderItem: 'flex items-center h-full',
				slideContent: 'relative z-1 h-full w-full',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls:
					'absolute w-full top-0 lg:bottom-0 lg:top-auto flex justify-end lg:justify-between items-center p-6',
				buttonWrapper:
					'bottom-0 hidden lg:inline-flex z-20 bg-secondary-500 rounded-full text-white px-2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center hidden sm:inline-flex text-md w-auto h-10',

				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper: ' block z-10',
				pagination:
					'inline-flex flex-wrap justify-end items-center list-none -mx-1 ',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-white/80 hover:bg-white/100 is-active:bg-secondary-500 transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},

			'hero-portal': {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'slide',
					autoplay: {
						delay: 6000,
					},
					slideEffect: {
						shadow: true,
						translate: ['120%', 0, -500],
					},
				},
				wrapper: 'relative w-full bg-secondary-surface',
				slider: 'h-full min-h-full',
				sliderItem:
					'flex items-center h-full aspect-[1/1] md:aspect-[3/2] lg:aspect-[16/9] xl:aspect-[21/9] max-h-screen',
				slideContent: 'relative z-1 h-full w-full',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: 'container mx-auto flex justify-end px-4',
				buttonWrapper:
					'absolute bottom-0 hidden sm:inline-flex my-6 z-20 bg-secondary-500 rounded-full text-white px-2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center hidden sm:inline-flex text-md w-auto h-10',

				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full top-0 block z-10 my-6 -translate-y-1/2 transform container mx-auto',
				pagination: 'flex flex-wrap justify-end items-center list-none -mx-1 ',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-white/80 hover:bg-white/100 is-active:bg-secondary-500 transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},

			topthema: {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'fade',
					autoplay: {
						delay: 8000,
					},
					fadeEffect: {
						crossFade: true,
					},
				},
				wrapper: 'h-full min-h-full relative w-full',
				slider: 'h-full min-h-full',
				sliderItem: 'h-full min-h-full',
				slideContent: 'relative z-10 h-full min-h-full',
				controls: 'mt-4',
				buttonWrapper:
					'absolute top-0 right-0 px-3 translate-y-1/2 transform z-20 space-x-2',
				button:
					'transition ease-out shadow-lg duration-300 justify-center items-center rounded-full text-center text-secondary-text bg-white hover:text-secondary-text--active focus:text-secondary-text--active text-sm w-12 h-12',
				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper: 'block',
				pagination: 'flex justify-center md:justify-end list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-secondary-element opacity-50 hover:opacity-75 is-active:opacity-100 transition focus-visible:scale-[90%] focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			'hero-slim': {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'fade',
					autoplay: {
						delay: 6000,
					},
					fadeEffect: {
						crossFade: true,
					},
				},
				wrapper: 'relative w-full bg-secondary-surface overflow-hidden',
				slider: 'h-full min-h-full',
				sliderItem:
					'flex items-center h-full aspect-[16/9] lg:aspect-[21/9] max-h-[55vh]',
				slideContent:
					'relative z-10 py-10 px-3 max-w-screen-sm mx-auto text-center text-shadow-sm flex h-full items-center justify-center',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: 'container mx-auto flex justify-end px-4',
				buttonWrapper:
					'absolute bottom-0 hidden sm:inline-flex my-6 z-20 bg-secondary-500 rounded-full text-white px-2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center hidden sm:inline-flex text-md w-auto h-10',

				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonDisabled: 'opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full top-0 block z-10 my-6 -translate-y-1/2 transform container mx-auto',
				pagination: 'flex flex-wrap justify-end items-center list-none -mx-1 ',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-white/80 hover:bg-white/100 is-active:bg-secondary-500 transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			bildergalerie: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoplay: false,
					initialSlide: 2,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 24,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 24,
						},
						1600: {
							slidesPerView: 4,
							spaceBetween: 24,
						},
					},
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: 'flex items-center justify-center',
				slideContent: 'h-full w-full',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 hidden -mt-4 p-2',
				button:
					'pointer-events-auto rounded-full transition ease-out duration-300 justify-center items-center text-center text-primary-text text-xl inline-flex border border-primary-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-primary-surface hover:bg-primary-surface--active is-active:bg-primary-element--active transition',
				paginationButtonActive: 'is-active',
			},
		},
	},
	NkCard: {
		variants: {
			default: {
				wrapper:
					'flex flex-col min-h-full h-full gap-3 overflow-hidden rounded-custom',
			},
			boxed: {
				wrapper:
					'flex flex-col min-h-full h-full justify-between bg-white flex flex-col overflow-hidden border border-neutral-200 rounded-custom',
			},
			imageOverlay: {
				wrapper:
					'block relative overflow-hidden rounded-custom h-full min-h-full',
				body: 'lg:absolute lg:bottom-0 lg:left-0 w-full px-5 pb-6 pt-10 lg:text-white text-neutral-text lg:bg-gradient-to-t lg:from-black/75 lg:to-black/0 text-shadow-sm flex flex-col gap-3',
				header: 'h-full min-h-full',
				image: 'w-full h-full',
				footer: '',
				link: 'lg:!text-white transition transform duration-300 ease-out hover:-translate-y-1 hover:scale-[97%] focus-visible:scale-[97%] shadow-md shadow-md',
			},
			imagePreview: {
				wrapper:
					'flex flex-col min-h-full h-full justify-between bg-white rounded-custom flex flex-col',
				body: 'flex flex-col gap-3 h-full py-6',
				header: '',
				image: '',
				footer: 'mt-auto p-4 pt-0',
				link: 'transition duration-300 ease-out transform hover:-translate-y-1 hover:text-neutral-on-surface hover:bg-neutral-surface--active focus:text-neutral-on-surface focus:shadow-md focus:bg-neutral-surface--active',
			},
		},
	},
	NkButton: {
		fixedClasses:
			'inline-flex items-center transition duration-300 ease-out font-medium relative rounded-full no-underline',
		disabled: 'opacity-50 cursor-not-allowed',
		expand: 'block w-full text-center justify-center',
		loading: 'relative',
		prependIconAttributes: {
			class: 'mr-1',
		},
		appendIconAttributes: {
			class: 'ml-1',
		},
		colors: {
			tertiary:
				'[&.nk-solid]:bg-tertiary-element [&.nk-solid]:hover:bg-tertiary-element--hover [&.nk-solid]:focus:bg-tertiary-element--active [&.nk-solid]:text-tertiary-on-element [&.nk-link]:border-tertiary-element [&.nk-link]:text-tertiary-text [&.nk-link]:hover:text-tertiary-text--hover [&.nk-link]:hover:border-tertiary-element--hover [&.nk-ghost]:text-tertiary-text [&.nk-ghost]:hover:text-tertiary-text--hover [&.nk-ghost]:hover:border-tertiary-element--hover [&.nk-outline]:border-tertiary-element [&.nk-outline]:text-tertiary-text [&.nk-outline]:hover:text-tertiary-text--hover [&.nk-outline]:hover:border-tertiary-element--hover',
			white:
				'[&.nk-solid]:bg-white [&.nk-solid]:hover:bg-white/85 [&.nk-solid]:focus:bg-white/75 [&.nk-solid]:text-neutral-500 [&.nk-link]:border-white [&.nk-link]:text-white [&.nk-link]:hover:text-white [&.nk-link]:hover:border-white/75 [&.nk-ghost]:text-white [&.nk-ghost]:hover:text-white/85 [&.nk-outline]:border-white [&.nk-outline]:text-white [&.nk-outline]:hover:text-white/85 [&.nk-outline]:hover:border-white/85',
		},
		variants: {
			link: 'nk-link border-b-2 !rounded-none',
		},
		sizes: {
			default: 'px-3 py-2 min-h-6 text-base',
			sm: 'px-3 py-2 text-sm',
			md: 'p-3 text-md',
			lg: 'p-3 text-lg',
			collapse: 'p-0',
		},
	},
	NkSidebar: {
		classes: {
			wrapper: 'space-y-8',
		},
	},
	NkBox: {
		variants: {
			default: 'bg-secondary-100 p-6 mb-3 rounded-custom',
			transparent: 'p-6',
			sidebar: 'bg-secondary-100 p-6 rounded-custom',
		},
	},
	NkDate: {
		variants: {
			default: {
				wrapper:
					'inline-flex flex-col leading-tight bg-secondary-surface text-secondary-on-surface text-center px-5 py-4 rounded-bl-custom',
				day: 'text-lg font-bold',
				month: 'text-base uppercase tracking-wide',
			},
		},
	},
	NkDrilldown: {
		fixedClasses: '',
		variants: {
			default: {
				menu: 'nk-drilldown bg-neutral-surface rounded overflow-hidden',
				menuInner: 'list-none',
				menuItem: 'border-b border-neutral-border last:border-0',
				menuItemButton:
					'relative flex w-full py-4 px-4 text-primary-text hover:bg-neutral-surface-hover',
				menuItemButtonActive:
					'border-b border-neutral-border text-primary-text',
				menuItemIcon: 'no-underline before:no-underline after',
				menuItemButtonIcon:
					'ml-auto no-underline absolute right-0 mr-3 transform transition-transform duration-300 ease-in-out ',
				menuItemButtonIconActive: 'rotate-180',
				menuItemLink:
					'block py-4 px-4 text-primary-text link-active:bg-primary-element link-active:text-primary-on-element link-active-exact:bg-primary-element link-active-exact:text-primary-on-element group-[.is-submenu]:pl-8',
				menuItemLinkIcon: 'ml-auto no-underline before:no-underline',
				submenu: 'bg-neutral-surface--active group is-submenu',
			},
		},
	},
	NkPagination: {
		classes: {
			link: 'inline-block text-type text-center hover:text-primary-text--hover focus:text-primary-text--active p-2 rounded-full',
			linkActive:
				'inline-block text-center text-primary-500 font-bold p-2 rounded-full',
			button:
				'inline-flex border-solid p-2 items-center justify-center bg-primary-element text-primary-on-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element rounded-full',
			buttonDisabled:
				'inline-flex border-solid bg-neutral-surface p-2 items-center justify-center cursor-not-allowed rounded-full',
		},
	},
	NkNav: {
		variants: {
			resources: {
				content: 'px-3 flex-1 overflow-hidden',
				link: 'flex items-center flex-no-wrap p-3 text-primary-text hover:text-primary-text--hover  transition duration-300 group',
				title:
					'font-bold group-hover:text-primary-text--hover group-focus:text-primary-text--active transition duration-300',
			},

			default: {
				wrapper: '',
				item: 'border-b border-neutral-border text-md',
				title: 'font-bold',
				text: '',
				content: 'inline-block text-base group-hover:underline ',
				link: 'flex py-3 px-2 transition duration-300 group text-neutral-text-high-contrast hover:text-neutral-text-high-contrast--hover',
				appendIcon: {
					icon: 'right-arrow-alt',
					class: 'ml-auto',
				},
				prependIcon: false,
			},
		},
	},
	NkImage: {
		classes: {
			container:
				'block relative bg-neutral-surface w-full rounded-custom overflow-hidden',
			copyright:
				'inline-block absolute z-30 opacity-75 leading-none rounded-full p-3 text-white text-sm',
			figcaption: 'h-auto relative italic my-2 text-base',
			image:
				'max-w-full w-full lazyload transition-opacity duration-500 ease-out delay-100',
			noteLabel:
				'absolute bottom-0 right-0 inline-flex items-center bg-black bg-opacity-75 m-2 px-2 py-1 text-white text-sm rounded whitespace-no-wrap',
			noteLabelIcon: 'mr-1 text-base',
			wrapper: 'relative',
		},
	},
	NkLink: {
		classes: '',
	},
	NkBreadcrumb: {
		classes: {
			link: 'text-primary-text hover:underline focus:underline inline-flex items-center align-middle link-active-exact:text-neutral-text',
		},
		homeIcon: {
			color: 'secondary',
			variant: 'boxed',
		},
	},
	NkTemplateAccessibility: {
		classes: {
			accessibilityLink: 'text-white focus:text-white hover:text-white',
			container:
				'container bg-white text-secondary-on-surface [&_a]:text-secondary-element [&_a:hover]:text-secondary-element [&_a:focus]:text-secondary-element [&_p]:text-secondary-on-surface',
		},
	},
	NkTemplateBildergalerieWebcontact: {
		cols: {
			default: 'full',
			sm: '6/12',
			md: '4/12',
			lg: '6/12',
			xl: '4/12',
		},
	},
};

export {settings};
